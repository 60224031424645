@import '../../styles/styles.scss';

.main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/textura_fondo_azul.jpg');
    font-family: $font-text;
    
    // &_header {
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     padding: 15px 30px 10px;
    //     display:flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: center;
    //     z-index: 10;
    //     border-bottom: 2px ridge $color-gold;

    //     &_title {
    //         width: 250px;
    //         height: 130px;
    //         background-image: url('../../assets/images/bienvenidos_maeron_dragon.png');
    //         background-size: cover;
    //         background-repeat: no-repeat;
    //         background-position-x: right;
    //     }
    
    //     &_menu {
    //         list-style: none;
    //         padding: 0;
    //         display:flex;
    //         flex-direction: row;
    
    //         li {
    //             padding: 0 5px;
    //         }
    
    //         &-link {
    //             @include link
    //         }
    //     }
    // }

    &_belly{
        flex-grow: 1;
        // padding: 10px 0px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }
}