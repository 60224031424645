@import '../../../styles/styles.scss';

.draggableMap {
    &__window {
    position: relative;
    height: 100%;
    }
}

.marker {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color:red;
}