@import '../../styles/styles.scss';

.background  {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 30;

    .tutorial {
        position: absolute;
        padding: 50px 70px;
        top: 50%;
        left: 50%;
        right: 0;
        height: 720px;
        width: 1050px;
        border: none;
        outline: none;
        transform: translate(-50%, -50%) rotate(0deg);
        background: bottom / cover no-repeat url('../../assets/images/old_paper_trans.png');
        filter: drop-shadow(5px 15px 7px rgba(0, 0, 0, 0.9));

        img {
            display: block;
            margin: 20px auto 30px;
            border: none;
            height: 300px;
        }

        aside {
            height: 500px;
            font-size: 15px;
            h1 {
                margin: 0;
                text-align: center;
                font-family: $font-title;
                font-size: 40px;
            }
            span {
                font-weight: 400;
                font-size: 30px;
            }
            ul {
                margin: 5px;
            }
            li {
                margin: 5px 0;
            }
            a {
                color: $color-dwarfs;
                &:visited {
                    color: $color-elves;
                }
            }
            .tutoDefault {
                text-align: center;
                padding: 0 100px;

            }
            .timelineTuto {
                padding: 0 70px;

            }
            div {
                padding: 0 150px;
            }
        }

        small {
            display: block;
            margin: 0 auto;
            text-align: center;
            font-size: 10px;
        }

        .mapBox {
            position: absolute;
            top: 127px;
            left: 300px;            
            height: 250px;
            width: 450px;
            padding: 0;
            border: solid 5px $color-dwarfs;
            transition: .5s;
            &:hover {
                border: solid 5px firebrick;
                background-color: rgba(firebrick, 0.15);
                transform: scale(1.01);
                filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.9));
                cursor: pointer;
            }
        }
        .markerBox {
            position: absolute;
            top: 100px;
            right: 240px;            
            height: 75px;
            width: 95px;
            padding: 0;
            border: solid 5px $color-elves;
            transition: .5s;
            &:hover {
                border: solid 5px #39a87e;
                background-color: rgba(#39a87e, 0.2);
                transform: scale(1.01);
                filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.9));
                cursor: pointer;
            }
        }
        .timelineBox {
            position: absolute;
            top: 360px;
            left: 240px;            
            height: 65px;
            width: 570px;
            padding: 0;
            border: solid 5px $color-humans;
            transition: .5s;
            &:hover {
                border: solid 5px #144396;
                background-color: rgba(#144396, 0.2);
                transform: scale(1.01);
                filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.9));
                cursor: pointer;
            }
        }
    }

    .button-close {
        position: absolute;
        top: 100px;
        right: 120px;
        height: 35px;
        width: 33px;
        background-color: transparent;
        background-image: url('../../../src/assets/images/closeIcon.png');
        border: none;
        outline: none;
        transform: scale(0.7);

        &:hover {
            transform: scale(0.75);
            cursor: pointer;
        }
    }
}