@import '../../styles/styles.scss';

.background  {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 30;


    .about {
        position: absolute;
        padding: 100px 120px;
        top: 50%;
        left: 50%;
        right: 0;
        height: 720px;
        width: 1050px;
        border: none;
        outline: none;
        transform: translate(-50%, -50%);
        background: bottom / cover no-repeat url('../../assets/images/old_paper_trans.png');
        filter: drop-shadow(5px 15px 7px rgba(0, 0, 0, 0.9));

        aside {
            height: 400px;
            h1 {
                text-align: center;
                font-family: $font-title;
                font-size: 40px;
                span {
                    font-weight: 400;
                    font-size: 30px;
                }
            }
            li {
                margin: 10px 0;
            }
            a {
                color: $color-dwarfs;
                &:visited {
                    color: $color-elves;
                }
            }
        }

        .bugfix {
            margin-top: 50px;
        }

        footer {
            display: block;
            margin: 0 auto;
            text-align: center;
        }
    }

    .button-close {
        position: absolute;
        top: 100px;
        right: 120px;
        height: 35px;
        width: 33px;
        background-color: transparent;
        background-image: url('../../../src/assets/images/closeIcon.png');
        border: none;
        outline: none;
        transform: scale(0.7);

        &:hover {
            transform: scale(0.75);
            cursor: pointer;
        }
    }
}