@import '../../styles/styles.scss';

.modal  {
    box-sizing: content-box;
    position: absolute;
    bottom: -200px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 0;
    width: 500px;
    padding: 0 30px 45px;
    background: bottom / cover no-repeat url('../../assets/images/old_paper_3.jpg');
    box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.5);
    transition: 1s ease-in-out;

    span {
        font-size: 20px;
        font-family: $font-caps;
        font-weight: 500;
    }

    &_button-close {
        position: absolute;
        right: 0;
        height: 35px;
        width: 33px;
        background-color: transparent;
        background-image: url('../../../src/assets/images/closeIcon.png');
        border: none;
        outline: none;
        transform: scale(0.55);

        &:hover {
            transform: scale(0.6);
            cursor: pointer;
        }
    }

    &_button-more-info {
        position: absolute;
        right: 35px;
        bottom: 60px;
        padding: 7px;
        background-color: transparent;
        border: none;
        outline: none;
        color: rgba(0, 0, 0, 0);
        font-family: $font-text;
        background:center / contain no-repeat url('../../../src/assets/images/sabermas.png');

        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
    }
}