//COLORS
$color-contrast: #ccbd95;
$color-gold: #e69a30;
$color-white: white;
$color-black: black;

$color-dwarfs: #7d1111;
$color-humans: #0e3069;
$color-elves: #297d5d;


//FONTS
$font-title: 'UnifrakturMaguntia', cursive;
$font-text:  'Metamorphous', cursive;
$font-caps: 'Grenze Gotisch', cursive;
