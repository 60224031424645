@import '../../styles/styles.scss';

.map {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &_header {
        padding: 0 30px;
    }

    &_info {
        box-sizing: border-box;
        padding: 20px 20px 140px;
        height: 100vh;
        width: 400px;
        font-family: $font-text;
        text-align: center;
        overflow: auto;
        &-img {
            max-width: 360px;
        }
    }

    &_modal {
        margin-bottom: 15px;
        box-sizing: border-box;
        width: 500px;
        font-family: $font-text;
    }

    &_footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 7px 0;
        background-color: transparent;
        background: left 0 /100% no-repeat url('../../assets/images/old_paper_3.jpg');
        box-shadow: 0px -5px 5px rgba($color-black, .5);
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 20;
        
        &__container {
            width: 100%;
            height: 100%;
            overflow:scroll hidden;
            scrollbar-width: thin;
        }
    }
}