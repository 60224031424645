@import '../../styles/styles.scss';

.info-panel {
    position: absolute;
    padding-bottom: 120px;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 400px;
    background: top 0 right -10px / cover no-repeat url('../../assets/images/old_paper_3.jpg');
    transition: 1.5s ease-in-out;
    box-shadow: 0px 5px 7px 7px rgba($color-black, .5);
    z-index: 10;
    line-height: 30px;

    span, h1 {
        font-size: 30px;
        font-family: $font-caps;
        font-weight: 500;
    }

    .infoLocation {
        font-family: $font-caps;
        font-size: 20px;
    }

    &_button-close {
        position: absolute;
        right: 0;
        height: 35px;
        width: 33px;
        background-color: transparent;
        background-image: url('../../../src/assets/images/closeIcon.png');
        border: none;
        outline: none;
        transform: scale(.65);


        &:hover {
            transform: scale(.7);
            cursor: pointer;
        }
    }
}