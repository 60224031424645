@import '../../../styles/styles.scss';

.timelineYears{
    &__line-container {
        // padding: 5px 0;
        display: flex;
        flex-wrap: nowrap;
    }
    
    // &__dot {
    //     margin: 0 auto;
    //     height: 16px;
    //     width: 16px;
    //     border-radius: 50%;
    //     background-color: $color-gold;
    //     transform: translateY(-3px);
    //     cursor: pointer;

    //     &:focus {
    //         background-color: #fff;
    //     }
    // }
    
    &__section {
        height: 15px;
        width: 50px;
        overflow-y: visible;
        flex-shrink: 0;
        text-align: center;
        font-size: 10px;
        &-init {
            width: 50px;
            background-color: #fff;
        } 
        &-end {
            width: 50px;
            background-color: #fff;
        }
    }
}