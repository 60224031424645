@import '../../styles/styles.scss';

.spinner_wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
}

.spinner {
    height: 88px;
    width: 88px;
    margin: 10% auto;
    animation: 4s linear infinite spin;
}

@keyframes spin {
    from {
        transform: rotateZ(0);
    }
    to {
        transform: rotateZ(360deg);
    }
}