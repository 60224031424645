@import '../../styles/styles.scss';

.markers  {
    position: absolute;
    top: -105px;
    right: 0;
    margin: 0 30px;
    padding: 30px 10px 185px;
    width: 200px;
    list-style: none;
    background: bottom / cover no-repeat url('../../assets/images/marker.png');
    filter: drop-shadow(2px 7px 3px rgba(0, 0, 0, 0.7));
    transition: ease-in-out .7s;
    text-align: center;

    &:hover {
                transform: translateY(90px);
            }
    
    button {
        padding: 5px 10px;
        font-family: $font-text;
        font-size:  16px;
        background-color: transparent;
        border: none;
        
        &:hover {
            cursor: pointer;
            color: $color-dwarfs;
            font-weight: bolder;
        }
    }
}