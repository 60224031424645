@import '../../styles/styles.scss';


.introBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: center / cover no-repeat url('../../assets/images/textura_fondo_azul.jpg');
    z-index: 40;
    transition: 1s;
    overflow: hidden;

    p {
        position: absolute;
        top: 0;
        left: 0;
        margin:10px;
        color: $color-contrast;
        z-index: 40;
    }

    input {
        opacity: 0;
    }
}


.introVideo  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 40;    
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 50%;
    z-index: 45;
    transition: .5s;

    &:hover {
        transform: translate(-50%, -50%) scale(1.02);
        filter: drop-shadow(5px 7px 5px rgba(0, 0, 0, 0.7));

        cursor: pointer;
    }
}