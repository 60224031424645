@import '../../styles/styles.scss';

.landing {
  text-align: center;

  &-header {
    background-image: url('../../assets/images/textura_fondo_azul.jpg');
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}

@media all and (max-width:987px) {
  .landing-title {
    width: 75%;
  }
}