@import '../../styles/styles.scss';

.mobileBackground {
    height: 100vh;
    width: 100%;
    background: center / cover no-repeat url('../../assets/images/tutorial/web.png');

    div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(black, 0.7);
    }

    .mobileText {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-contrast;
        text-align: center;
        font-size: 20px;
    }
}