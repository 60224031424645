@import '../../../styles/styles.scss';

.mapDisplay {
    flex-grow: 1;
    overflow: hidden;

    &__location {
        opacity: 0;
        position: absolute;
        transform: translate(-56px, -47px);
        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }
}
