@import '../../../styles/styles.scss';


.timeline {
    &__button {
        position: absolute;
        padding: 1px 6px;
        top: -20px;
        height: 20px;
        font-family: $font-text;
        color: $color-contrast;
        border: none;
        outline: none;
        border-radius: 3px 3px 0 0;
    }

    &__line-container {
        padding: 5px 0;
        display: flex;
        flex-wrap: nowrap;
    }
    
    &__dot {
        margin: 0 auto;
        height: 16px;
        width: 20px;
        background-image: url('../../../../src/assets/images/dot.png');
        background-position: center;
        background-repeat: no-repeat;
        transform: translateY(-3px);
        cursor: pointer;

        &:focus {
            background-color: #fff;
        }
    }
    
    &__section {
        height: 10px;
        width: 50px;
        overflow-y: visible;
        flex-shrink: 0;
        &-init {
            width: 50px;
            background-color: #fff;
        } 
        &-end {
            width: 50px;
            background-color: #fff;
        }
    }
}